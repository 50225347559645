export const validatePhoneNumber = phone => /^380(\d{9})$/.test(phone)

export const validateInn = inn => /^(\d{10})$/.test(inn)

export const validateName = name =>
  /^[A-Za-zА-Яа-яЇїІіЄє\s]+$/.test(name) && name.replace(/\s/g, "").length > 2

export const transformPhoneAfterMask = str => str.replace(/[-+()\s]/g, "")

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
