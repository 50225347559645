//Core
import React from "react"

//Styles
import style from "./style.module.scss"

//Images
import waiterImage from "../../../images/waiter.png"
import logo_header from "../../../images/logo_full.png"

const { Loader, waiter, Logo, Image, LoadImage, hidden } = style

const LoaderComponent = ({ loaded }) => {
  return (
    <div className={[Loader, loaded ? " " : hidden].join(" ")}>
      <img src={logo_header} className={Logo}></img>
      <div className={waiter}>
        <img className={Image} src={waiterImage}></img>

        <div className={LoadImage}>
          <img src={waiterImage}></img>
        </div>
      </div>
    </div>
  )
}

export default LoaderComponent
