//Core
import React, { useEffect, useState } from "react"

//Components
import Seo from "./Seo"
import Header from "./Header"
import Loader from "./Loader"
import Footer from "./Footer"

//Styles
import style from "./style.module.scss"

//Images
import terminator from "../../images/terminator.png"
import terminator2 from "../../images/terminator-genesis-backgrounds-robot-face-wallpaper-preview.jpg"

const { Preloader, ImageBG, Image } = style

const Layout = ({ description, title, link, children }) => {
  const [show, setShow] = useState(true)

  const hideLoader = () => setShow(false)

  useEffect(() => {
    setTimeout(() => {
      hideLoader()
    }, 500)
    console.log("loaded")
  }, [])

  return (
    <div>
      <Seo title={title} description={description} link={link}></Seo>
      {/* <div className={Preloader}>
        <div className={ImageBG}>
          <img src={terminator} className={Image}></img>
        </div>
      </div> */}
      <Loader loaded={show}></Loader>
      <Header></Header>
      <main>{children}</main>
      <Footer></Footer>
    </div>
  )
}

export default Layout
