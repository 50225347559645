//Cire
import React from "react"

//Components
// import Social from "./Social"

//Styles
import style from "./style.module.scss"
import Bounce from "react-reveal/Bounce"

const { Container, Title, Subtitle, Write } = style

const TextWrapper = () => {
  return (
    <Bounce cascade left>
      <div className={Container}>
        <div className={Title}>Contact us</div>
        <div className={Subtitle}>Do you have any questions?</div>
        <div className={Write}>Write to us!</div>
        {/* <Social></Social> */}
      </div>
    </Bounce>
  )
}

export default TextWrapper
