//Core
import React from "react"

//Styles
import "./index.scss"

const LightSpinner = () => {
  return (
    <div className="spinner-box">
      <div className="circle-border">
        <div className="circle-core"></div>
      </div>
    </div>
  )
}

export default LightSpinner
