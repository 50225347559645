//Core
import React from "react"

//Styled
import "./style.scss"

const Burger = ({ toggleMenu, menu }) => {
  return (
    <div className="BurgerContainer">
      <div>
        <button
          onClick={() => toggleMenu(!menu)}
          className={`hamburger hamburger--collapse ${
            menu ? "is-active" : " "
          } `}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  )
}

export default Burger
