//Core
import React, { useReducer } from "react"

//Action
import formActions from "../actions"

//Itils
import axios from "axios"

//Helpers
import { validateName, validateEmail } from "../helpers/validation"

const {
  START_FETCH,
  FINISH_FETCH,
  FETCH_SUCCESS,
  FETCH_FAIL,
  CHANGE_NAME,
  VALID_NAME,
  CHECKED_NAME,

  CHANGE_MAIL,
  VALID_MAIL,
  CHECKED_MAIL,
  CHANGE_MESSAGE,
} = formActions

const initialState = {
  fetch: false,
  success: false,
  fail: false,
  name: { valid: false, value: "", checked: false },
  mail: { valid: false, value: "", checked: false },
  message: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case START_FETCH:
      return { ...state, fetch: true }
    case FINISH_FETCH:
      return { ...state, ...initialState }
    case FETCH_SUCCESS:
      return { ...state, fetch: false, success: true }
    case FETCH_FAIL:
      return { ...state, fetch: false, fail: true }

    case CHANGE_NAME:
      return { ...state, name: { ...state.name, value: action.payload } }
    case CHANGE_MESSAGE:
      return { ...state, message: action.payload }
    case VALID_NAME:
      return {
        ...state,
        name: { ...state.name, valid: action.payload },
      }

    case CHECKED_NAME:
      return { ...state, name: { ...state.name, checked: true } }

    case CHECKED_MAIL:
      return { ...state, mail: { ...state.mail, checked: true } }
    case CHANGE_MAIL:
      return { ...state, mail: { ...state.mail, value: action.payload } }
    case VALID_MAIL:
      return {
        ...state,
        mail: { ...state.mail, valid: action.payload },
      }

    default:
      return state
  }
}

const useForm = link => {
  const [state, dispatch] = useReducer(reducer, initialState)

  //UI
  const startFetch = () => dispatch({ type: START_FETCH })
  const finishFetch = () => dispatch({ type: FINISH_FETCH })
  const fetchSuccess = () => dispatch({ type: FETCH_SUCCESS })
  const fetchFail = () => dispatch({ type: FETCH_FAIL })

  //Name
  const checkedName = () => dispatch({ type: CHECKED_NAME })

  const validName = isValidName =>
    dispatch({ type: VALID_NAME, payload: isValidName })

  const changeName = name => {
    dispatch({ type: CHANGE_NAME, payload: name })
    const isValidName = validateName(name)

    validName(isValidName)
  }

  //Mail
  const checkedMail = () => dispatch({ type: CHECKED_MAIL })

  const validMail = isValidMail =>
    dispatch({ type: VALID_MAIL, payload: isValidMail })

  const changeMail = mail => {
    dispatch({ type: CHANGE_MAIL, payload: mail })
    const isValidMail = validMail(mail)

    validMail(isValidMail)
  }

  //Message
  const changeMessage = message => {
    dispatch({ type: CHANGE_MESSAGE, payload: message })
  }

  const onClick = async () => {
    try {
      const { mail, name, message } = state

      //Validate fileds
      const isValidMail = validateEmail(mail.value)
      const isValidName = validateName(name.value)

      checkedMail()
      checkedName()

      //Set fields
      validMail(isValidMail)
      validName(isValidName)

      console.log("isValidMail", isValidMail)
      console.log("isValidName", isValidName)

      console.table({
        name,
        mail,
        message,
      })

      if (isValidName && isValidMail) {
        startFetch()
        try {
          console.table({
            name: name.value,
            mail: mail.value,
            message,
          })

          const res = await axios.post(link, {
            name: name.value,
            phone: mail.value,
            message,
          })

          console.log(res)
          res.status === 201 || res.status == 200 ? fetchSuccess() : fetchFail()
        } catch (error) {
          console.log("error", error)
          fetchFail()
        } finally {
          finishFetch()
        }
      }
    } catch (error) {
      console.log("err", error)
    }
  }

  return {
    state,

    changeName,
    checkedName,

    changeMail,
    checkedMail,

    changeMessage,

    onClick,
  }
}

export default useForm
