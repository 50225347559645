import React, { useState } from "react"

//Components
import Menu from "./Menu"
import Burger from "./Burger"

//style
import style from "./style.module.scss"
import "./index.scss"

import main_img from "../../../images/main.jpg"

const GhostImage = () => (
  <div
    className="c-glitch__img"
    style={{
      backgroundImage: `url(
      ${main_img}
    )`,
    }}
  ></div>
)

const Header = () => {
  const [menu, toggleMenu] = useState(false)

  return (
    <>
      <Burger menu={menu} toggleMenu={toggleMenu}></Burger>
      <header className={style.header}>
        <Menu menu={menu} toggleMenu={toggleMenu}></Menu>
        <div
          className="c-glitch"
          style={{
            backgroundImage: `url(
              ${main_img}
            )`,
          }}
        >
          {Array(5)
            .fill(1)
            .map(() => (
              <GhostImage></GhostImage>
            ))}
          <div className="logo"></div>
        </div>
        <div>
          <span className={[style.type_error, style.red].join(" ")}>
            TypeError
          </span>
          <span className={[style.type_error, style.blue].join(" ")}>
            TypeError
          </span>
          <span className={[style.type_error, style.white].join(" ")}>
            TypeError
          </span>
        </div>
      </header>
    </>
  )
}

export default Header
