//Core
import React from "react"

//style
import style from "./style.module.scss"

//Images
import casino from "../../../images/partners/c4sino.svg"
import litfit from "../../../images/partners/litfit.svg"
import minialo from "../../../images/partners/minialo.png"

//Components
import StatElement from "./StatElement"

const statistics = [
  {
    img: litfit,
    subtitle: "Litfit",
    title: "Courses Agregator",
  },
  {
    img: casino,
    subtitle: "c4sino",
    title: "Affiliate Casino System",
  },
  {
    img: minialo,
    subtitle: "Miniailo",
    title: "Author library",
  },
]

const Stats = () => {
  return (
    <section className={style.stats}>
      <h2 data-text="Our partners" className="glitch_psycho">
        Our partners
      </h2>

      <div className={style.featureContainer}>
        {statistics.map(stat => (
          <StatElement key={stat.subtitle} {...stat}></StatElement>
        ))}
      </div>
    </section>
  )
}

export default Stats
