import React from "react"

import "./index.scss"

const Glitch = ({ text, hover, small }) => {
  return (
    <div className="glitch-wrapper">
      <div className={`glitch ${small ? "small" : " "}`} data-text={hover}>
        {text}
      </div>
    </div>
  )
}

export default Glitch
