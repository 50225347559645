//Core
import React from "react"

//style
import style from "./style.module.scss"
import Bounce from "react-reveal/Bounce"

const StatElement = ({ title, img }) => {
  return (
    <Bounce>
      <div className={style.StatsElement}>
        <div className={style.textContainer}>
          <img src={img} alt="TypeError stack"></img>
          <h3 className={style.title}>{title}</h3>
        </div>
      </div>
    </Bounce>
  )
}

export default StatElement
