export default {
  START_FETCH: "START_FETCH",
  FINISH_FETCH: "FINISH_FETCH",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAIL: "FETCH_FAIL",
  ORDER_CARD: "ORDER_CARD",
  CHANGE_NAME: "CHANGE_NAME",
  VALID_NAME: "VALID_NAME",
  CHECKED_NAME: "CHECKED_NAME",
  CHANGE_INN: "CHANGE_INN",
  VALID_INN: "VALID_INN",
  CHECKED_INN: "CHECKED_INN",

  CHANGE_PHONE: "CHANGE_PHONE",
  VALID_PHONE: "VALID_PHONE",
  CHECKED_PHONE: "CHECKED_PHONE",

  CHANGE_MAIL: "CHANGE_MAIL",
  VALID_MAIL: "VALID_MAIL",
  CHECKED_MAIL: "CHECKED_MAIL",

  CHANGE_MESSAGE: "CHANGE_MESSAGE",

  RESET_FORM: "RESET_FORM",
}
