//Core
import React from "react"

//styles
import "./index.scss"
import jump from "jump.js"

const MenuItem = ({ children, click }) => (
  <figure onClick={click} className="glitch-filter-example">
    <div className="glitch-filter-example__filtered-text nav-item">
      {children}
    </div>
  </figure>
)

const Menu = ({ menu, toggleMenu }) => {
  return (
    <div
      className={`header ${menu ? "active" : " "}`}
      onClick={() => toggleMenu(!menu)}
    >
      <div className="nav">
        <div>
          <MenuItem click={() => jump("#SERVICE", { offset: -100 })}>
            SERVICES
          </MenuItem>
          <MenuItem click={() => jump("#SERVICE", { offset: -100 })}>
            BENEFITS
          </MenuItem>
        </div>
        <div>
          <MenuItem click={() => jump("#ABOUT_US", { offset: -100 })}>
            ABOUT US
          </MenuItem>
          <MenuItem click={() => jump("#CONTACT", { offset: -100 })}>
            CONTACT US{" "}
          </MenuItem>
        </div>
      </div>
    </div>
  )
}

export default Menu
