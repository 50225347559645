import Swal from "sweetalert2"

export const onSuccess = (message = `We will write you`) => {
  Swal.fire({
    title: "Success!",
    text: `${message}`,
    background: `url("https://s6.gifyu.com/images/giphya2b4f209daaf2144.gif")`,
    backdrop: `
    rgba(0,0,13,0.4)
    url("https://sweetalert2.github.io/images/nyan-cat.gif")
    left top
    no-repeat
  `,

    icon: "success",
  })
}
export const onFail = () => {
  Swal.fire("Error!", `Something went wrong`, "error")
}

{
  // /* <img src={`https://www.likeni.ru/upload/medialibrary/e5e/6.gif`}></img> */
}
