//Core
import React from "react"

//Styles
import style from "./style.module.scss"

const Footer = () => {
  return <footer className={style.footer}></footer>
}

export default Footer
