//Core
import React from "react"

//Styles
import style from "./style.module.scss"

//Custom Hooks
import useForm from "../../../customHook/useForm"

//Components
import InputSimple from "../../InputSimple"
import { onFail, onSuccess } from "../../UI/PopUp"
import LigthSpinner from "../../UI/LigthSpinner"

const { Container, Button, Form } = style

const FormComponents = ({ link }) => {
  const {
    state,
    changeName,
    changeMail,

    checkedName,
    checkedMail,

    changeMessage,

    onClick,
  } = useForm(link)

  const { name, mail, fetch, success, fail } = state

  return fetch ? (
    <LigthSpinner />
  ) : (
    <>
      <div id="form" className={Container}>
        <form
          className={Form}
          onSubmit={e => {
            e.preventDefault()
            onClick()
          }}
        >
          <InputSimple
            change={changeName}
            message={"Check name"}
            checked={name.checked}
            placeholder={"Your name"}
            valid={name.valid}
            type="text"
            checkedFunc={checkedName}
          ></InputSimple>
          <InputSimple
            placeholder={"Your e-mail address"}
            change={changeMail}
            checkedFunc={checkedMail}
            checked={mail.checked}
            valid={mail.valid}
            message={"Check you mail"}
            type="text"
          ></InputSimple>
          <InputSimple
            placeholder={"Message text"}
            change={changeMessage}
            full
            type="text"
          ></InputSimple>
          <button className={Button} type="submit"></button>
        </form>
      </div>

      {success ? onSuccess() : null}
      {fail ? onFail() : null}
    </>
  )
}

export default FormComponents
