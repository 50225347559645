//Core
import React from "react"

//style
import style from "./style.module.scss"
import Bounce from "react-reveal/Bounce"

const { videoWrap, text, title, description } = style

const Video = () => {
  return (
    <div className={videoWrap} id="ABOUT_US">
      <div className={text}>
        <Bounce top>
          <h2 className={description}>We design and develop</h2>
        </Bounce>
        <Bounce bottom>
          <div className={title}>
            We break stereotypes , but create new standards for <span>IT </span>
            solutions
          </div>
        </Bounce>
      </div>
    </div>
  )
}

export default Video
