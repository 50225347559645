//Core
import React from "react"

//Style
import style from "./style.module.scss"

const { Container, Input, Warning, success, error } = style

const InputComponents = ({
  type,
  message,
  valid,
  checked,
  change = () => {},
  placeholder,
  full = false,
  checkedFunc = () => {},
}) => {
  return (
    <div className={Container}>
      {full ? (
        <textarea
          className={[Input].join(" ")}
          placeholder={placeholder}
          onChange={({ target: { value } }) => change(value)}
        ></textarea>
      ) : (
        <input
          className={[
            Input,
            valid ? success : "",
            checked && !valid ? error : " ",
          ].join(" ")}
          type={type}
          placeholder={placeholder}
          onChange={({ target: { value } }) => change(value)}
          onBlur={() => checkedFunc()}
        ></input>
      )}

      {checked ? valid ? null : <p className={Warning}>{message}</p> : null}
    </div>
  )
}

export default InputComponents
