import React from "react"

//Components
import China from "../components/China"
import Video from "../components/Video"
import ContactUs from "../components/ContactUs"
import Stats from "../components/UselessComponents/Stats"

//Container
import Layout from "../container/Layout"

//styles
import "../themes/main.scss"

const IndexPage = () => {
  return (
    <Layout
      title={`TypeError | Outsource at your service`}
      description={`We are an outsourcing company dealing with web development, mobile application development`}
      link={`https://typerror.co`}
    >
      <China></China>
      <Stats></Stats>
      <Video></Video>
      <ContactUs></ContactUs>
      {/* 
      <GlitchGift>
        <Glitch text="_#BREAKING#_" hover=" STEREOTYPES"></Glitch>
      </GlitchGift> */}
    </Layout>
  )
}

export default IndexPage
