//Core
import React from "react"

//Styles
import style from "./style.module.scss"

//Components
import TextWrapper from "./TextWrapper"

import Form from "./Form"

const Footer = () => {
  return (
    <div className={style.Container} id="CONTACT">
      <div className={style.Wrapper}>
        <TextWrapper></TextWrapper>
        <Form link={`https://type-error-server.herokuapp.com/api`}></Form>
      </div>
    </div>
  )
}

export default Footer
