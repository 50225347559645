//Core
import React from "react"

//images
import china from "../../images/china.jpg"

//Components
import Glitch from "../Glitch"

//style
import style from "./style.module.scss"
import Fade from "react-reveal/Fade"
import Bounce from "react-reveal/Bounce"

import "./break.scss"

const expertise = [
  `Software development : Web services, mobile applications`,
  `Cloud solutions`,
  `DevOps`,
  `CyberSecurity`,
  `QA`,
  `Business Analysis`,
  `UX/UI design`,
  `Marketing`,
  `Artificial Intelligence and Data science`,
]

const { chinaBg, text, list, GlitchWrapper } = style

const China = () => {
  return (
    <div className={chinaBg} id="SERVICE">
      <Bounce left>
        <img src={china} alt="Type Error china" />
      </Bounce>
      <div className={text}>
        <h2 data-text="Our expertise" className="glitch_psycho">
          Our expertise
        </h2>
        <Fade cascade right>
          <ul className={list}>
            {expertise.map((item, index) => (
              <li key={index}>
                <p className="underlined underlined--offset">{item}</p>
              </li>
            ))}
          </ul>
        </Fade>
      </div>
      <div className={GlitchWrapper}>
        <Glitch text="TYPE ERROR" hover="ERROR TYPE"></Glitch>
      </div>
    </div>
  )
}

export default China
